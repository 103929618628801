@import "variables";
@import "blog-list";
@import "button";
@import "image";
@import "list";
@import "portfolio";
@import "space";

body {
  font-family: 'Nunito', Helvetica, sans-serif;
  margin: 0;
  padding: 0;
  font-size: 18px;
}

@media (min-width: 750px) {
  body {
    font-size: 20px;
  }
}

.container {
  width: 85%;
  max-width: 900px;
  margin: auto;
}

header {
  width: 100%;
  margin: 0 0 2.5em 0;
  padding: 2em 0;
  background-color: lighten($brand-color, 70%);

  p {
    font-size: 1.5em;

    &.subtitle {
      font-size: 1em;
      opacity: 0.5;
    }
  }

  .blog-link {
    display: block;
    font-size: 1.3em;
    border: 2px solid $brand-color;
    padding: 1em;
    margin: 1.5em 0 1.2em 0;
  }
}

footer {
  width: 100;
  margin: 2.5em 0 0 0;
  padding: 2em 0;
  background-color: lighten($brand-color, 70%);
  text-align: center;
  font-size: 1.2em;
}

h1.site-title {
  font-family: 'Roboto Slab', serif;

  a {
    text-decoration: none;
  }
}

h1, h2, h3, h4, h5 {
  color: $brand-color;
}

h1 {
  font-size: 3em;
}

h2 {
  font-size: 2.4em;
}

h3 {
  font-size: 1.5em;
}

a {
  color: $brand-color;

  &:hover {
    color: darken($brand-color, 10%);
  }
}
