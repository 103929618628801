img.img-center {
  margin: 1.5em auto;
  display: block;
}

img.img-block {
  display: block;
  width: 100%;
  margin: 2em auto;
  max-width: 900px;
}

img.img-icon {
  vertical-align: middle;
}
