@import "space";
@import "variables";

a.portfolio-back {
  display: inline-block;
  margin: 0.8em 0;
  font-size: 1.3em;
  text-decoration: none;
  border-bottom: 2px dashed $brand-color;
}

.portfolio-item-time-period {
  color: #333333;
  padding-left: 3px;
}

.portfolio-list {
  .portfolio-list-item {
    a {
      border-radius: 3px;
      background-color: $brand-color;
      @extend .u-paddingAl;
      color: #FFF;
      display: block;
      text-decoration: none;
      border-bottom: 2px solid white;

      &:hover {
        background-color: darken($brand-color, 10%);
      }
    }

    &.portfolio-list-item--more {
      a > span {
        opacity: 0.5;
      }
    }

    .portfolio-list-item-date {
      color: #FFF;
      opacity: 0.7;
    }
  }
}

.portfolio-projects {
  .portfolio-project {
    padding: 2.5em 0;
    border-top: 1px solid $brand-color;

    h3 {
      margin-top: 0;
    }

    a.portfolio-project-link {
      display: inline-block;
      margin: 0.5em 0;
      font-size: 1em;
    }


    &:first-child {
      padding-top: 0;
      border-top: none;
    }
  }
}
