ul.blog-list {
  list-style-type: none;
  font-size: 1.3em;

  li {
    margin: 0.75em 0;
    display: block;
    overflow: hidden;
    color: $brand-color;
    text-decoration: none;

    a {
      text-decoration: none;
    }

    span.meta {
      color: grey;
      opacity: 0.7;
    }
  }
}